import { getLocaleData } from './locale';

const getWeekDayForLocale = (locale: string, weekdaysProp: 'weekdaysMin' | 'weekdaysShort') => {
  const localeData = getLocaleData(locale);
  const weekdayValues = localeData[weekdaysProp]();
  const weekStart = localeData.firstDayOfWeek();

  return [...weekdayValues.slice(weekStart), ...weekdayValues.slice(0, weekStart)];
};

export const getFirstDayOfWeekIndex = (locale: string): number =>
  getLocaleData(locale).firstDayOfWeek();

export const getWeekdaysMin = (locale: string): string[] =>
  getWeekDayForLocale(locale, 'weekdaysMin');

export const getWeekdaysShort = (locale: string): string[] =>
  getWeekDayForLocale(locale, 'weekdaysShort');
