import dayjs from 'dayjs';

import { formatDate } from '@Dates/dates';

export const formatMonthYear = (locale: string, yearMonth: string) =>
  dayjs(yearMonth, 'YYYY-MM').locale(locale).format('MMMM YYYY');

export const fullYearMonthsFromNow = (locale: string, date = new Date()): string[] => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return months
    .concat(months.splice(0, month - 1))
    .map((m, i) => {
      const y = m !== i + 1 && m < month && i > 0 ? year + 1 : year;

      return formatDate(locale, `${y}-${m}`, 'YYYY-m', 'YYYY-mm');
    })
    .concat(day > 1 ? [formatDate(locale, `${year + 1}-${month}`, 'YYYY-m', 'YYYY-mm')] : []);
};
