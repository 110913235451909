import dayjs from 'dayjs';

import { utcDate } from './dates';
import { getFirstDayOfWeekIndex } from './weeks';

const getDayGrid = (locale: string, yearMonth: string): (dayjs.Dayjs | null)[] => {
  const daysArray: (dayjs.Dayjs | null)[] = [];
  const date = utcDate(yearMonth, 'YYYY-MM');
  const firstDayOfMonth = date.day();

  for (let i = 0; i < date.daysInMonth(); i++) {
    const label = i + 1;
    const date = utcDate(`${yearMonth}-${label}`, 'YYYY-MM-D');
    daysArray.push(date);
  }

  const weekStartDay = getFirstDayOfWeekIndex(locale);

  const dayOfWeekUsingLocale =
    firstDayOfMonth - weekStartDay < 0
      ? firstDayOfMonth - weekStartDay + 7
      : firstDayOfMonth - weekStartDay;

  for (let i = 0; i < dayOfWeekUsingLocale; i += 1) {
    daysArray.unshift(null);
  }

  const remainingDates = 7 - (daysArray.length % 7);

  if (remainingDates !== 7) {
    for (let i = 0; i < remainingDates; i += 1) {
      daysArray.push(null);
    }
  }

  return daysArray;
};

export const getDayGridInWeeks = (locale: string, yearMonth: string) => {
  const dayGrid = getDayGrid(locale, yearMonth);
  const weeks = [];
  while (dayGrid.length > 0) {
    const week = dayGrid.splice(0, 7);
    weeks.push(week);
  }

  return weeks;
};
