import dayjs from 'dayjs';

const GQL_TIME_FORMAT = 'HH:mm';

const LOCAL_TIME_FORMAT = 'LT';

export const formatTime = (
  locale: string,
  time: string,
  currentTimeFormat: string,
  format = 'LT',
) => dayjs(time, currentTimeFormat).locale(locale).format(format).toLowerCase();

export const formatLocalTime = (locale: string, time: string) =>
  dayjs(time, GQL_TIME_FORMAT).locale(locale).format(LOCAL_TIME_FORMAT).toLowerCase();
